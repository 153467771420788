<script setup lang="ts">
import Organizations from '~/components/Organizations.vue'
import ChartSwitcher from './ChartSwitcher.vue'
import { useSession } from '#imports'
import { signOut } from '~/utilities/auth'

const { hasPreDxAnnotatorPermission } = useSession()

const logout = async () => {
  await signOut()
}
</script>

<template>
  <header class="flex w-full bg-brand-dark justify-between">
    <Suspense>
      <Organizations />
    </Suspense>
    <ChartSwitcher v-if="hasPreDxAnnotatorPermission" data-testid="headerChartSwitcher" />
    <button type="button" class="p-4 text-white opacity-80" @click="logout">LOG OUT</button>
  </header>
</template>
