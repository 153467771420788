<script setup lang="ts">
import { useMainStore } from '~/stores/main'

const mainStore = useMainStore()
</script>

<template>
  <div class="flex items-center space-x-4">
    <button
      type="button"
      class="bg-[#54266A] text-white opacity-80 font-medium py-2 px-3 rounded-full hover:bg-[#4A1E5C]"
      :class="{
        'bg-opacity-100': mainStore.isActiveChartIncomplete,
        'bg-opacity-30': mainStore.isActiveChartComplete,
      }"
      @click="mainStore.setActiveChart('incomplete')"
    >
      Ready for Chart Audit
    </button>
    <button
      type="button"
      class="bg-[#54266A] text-white opacity-80 font-medium py-2 px-3 rounded-full hover:bg-[#4A1E5C]"
      :class="{
        'bg-opacity-100': mainStore.isActiveChartComplete,
        'bg-opacity-30': mainStore.isActiveChartIncomplete,
      }"
      @click="mainStore.setActiveChart('complete')"
    >
      Completed
    </button>
  </div>
</template>
