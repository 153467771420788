<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import type { Organization } from '~/api'
import { useMainStore } from '~/stores/main'
import { storeToRefs } from 'pinia'

const mainStore = useMainStore()
const { selectedOrganization, organizationsList } = storeToRefs(mainStore)

await mainStore.fetchOrganizations()

const selectOrganization = (organization: Organization | null): void => {
  selectedOrganization.value = organization
}
</script>

<template>
  <Menu as="div" class="p-4 text-white relative inline-block text-left">
    <div>
      <MenuButton
        id="organization-menu-button"
        class="inline-flex w-full font-medium text-base justify-center gap-x-1.5 px-3 py-2 hover:bg-[#54266A]"
      >
        <img src="~/assets/icons/icon-32x32.png" alt="icon" class="h-6 w-6" />
        {{ selectedOrganization != null ? selectedOrganization.name : 'All Organizations' }}
        <ChevronDownIcon class="-mr-1 h-6 w-6 text-gray-400" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <a
              href="#"
              :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
              @click="selectOrganization(null)"
            >
              All Organizations
            </a>
          </MenuItem>
        </div>
        <div class="py-1">
          <MenuItem v-for="organization in organizationsList" :key="organization.id" v-slot="{ active }">
            <a
              href="#"
              :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
              @click="selectOrganization(organization)"
            >
              {{ organization.name }}
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
